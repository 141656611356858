
import $ from "jquery";

// SP時のメニューの開閉
$(function(){
    $(".nav_list li span").on("click", event =>{
        $(event.currentTarget).toggleClass("open");
        $(event.currentTarget).next().slideToggle();
    })
})


const drawerOpen = document.querySelector("#drawer_open");
const drawerClose = document.querySelector("#drawer_close");
const drawer = document.querySelector("#drawer");

drawerOpen.addEventListener("click", () => {
    drawer.classList.add("open");
})

drawerClose.addEventListener("click", () => {
    drawer.classList.remove("open");
})



// const targetContentsFooter = document.querySelector("#targetContentsFooter");
// const contentsFooterRecruit = document.querySelector("#contentsFooterRecruit");
// const contentsFooterContact = document.querySelector("#contentsFooterContact");

// contentsFooterRecruit.addEventListener("mouseover", () => {
//     alert("on")
//     targetContentsFooter.classList.remove("targetContact");
//     targetContentsFooter.classList.add("targetRecruit");
// })

// contentsFooterContact.addEventListener("mouseover", () => {
//     targetContentsFooter.classList.remove("targetRecruit");
//     targetContentsFooter.classList.add("targetContact");
// })

$(function(){
    $("#contentsFooterRecruit").on({
        'mouseenter' : 
            function() {
                $('#targetContentsFooter').removeClass("targetContact");
                $('#targetContentsFooter').addClass("targetRecruit");
            },
        
        'mouseleave' : 
            function(){
                $('#targetContentsFooter').removeClass("targetContact");
                $('#targetContentsFooter').removeClass("targetRecruit");
            }
    })
    
    $("#contentsFooterContact").on({
        'mouseenter' : 
            function() {
                $('#targetContentsFooter').removeClass("targetRecruit");
                $('#targetContentsFooter').addClass("targetContact");
            },
        
        'mouseleave' : 
            function(){
                $('#targetContentsFooter').removeClass("targetRecruit");
                $('#targetContentsFooter').removeClass("targetContact");
            }
    })
})





const accordionBtn = document.querySelectorAll(".information_btn");
accordionBtn.forEach(item => {
    item.addEventListener("click", target => {
        if(target.currentTarget.classList.contains("open")){
            target.currentTarget.classList.remove("open")
        }else{
            target.currentTarget.classList.add("open")
        }
    })
})
